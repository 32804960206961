import React from 'react'

const RenderMultiline = ({string}) => {
    const renderLines = string.split('\n').map((line,index) => <p key={index}>{line}</p>)
    return(
        <>
            {renderLines}
        </>
    )
}

export default RenderMultiline
