import React from "react"
import Layout from "../components/layout"
import {Container} from 'react-bootstrap'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

//components
import RenderMultiLine from '../components/RenderMultiline'

const IndexPage = ({data}) => {
    const pageData = data.contentfulPage;
    return(
        <Layout>
            <section className="section">
                <Container>
                    <div className="has-padding-large has-bg-white">
                        <h1 className="heading">{pageData.title}</h1>
                        <section className="section-intro">
                            <RenderMultiLine string={pageData.introduction.introduction}/>
                        </section>
                        <section className="section-body wysiwyg">
                            {documentToReactComponents(data.contentfulPage.content.json)}
                            <h4>Cookie instellingen aanpassen:</h4>
                            <p>
                                <button className="btn btn-primary tm-denyAll">Trek cookie acceptatie in</button><button className="btn btn-primary tm-acceptAll">Accepteer alle cookies</button>
                            </p>
                        </section>
                    </div>
                </Container>
            </section> 
        </Layout>
    )
}

export const query = graphql`
    query {
        contentfulPage(slug: {eq: "privacy-statement"}) {
            title
            introduction {
                introduction
            }
            content {
                json
            }
        }
    }
`

export default IndexPage

